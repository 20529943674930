<nav class="navbar custom-navbar navbar-light main-footer small">
  <ul class="navbar-nav mr-auto">
    <li class="nav-item">
      <a class="nav-link" href="javascript:;">
        <span>Copyright &copy; 2020</span> <span class="ff-headers text-uppercase">Telintec</span> 
      </a>
    </li>
  </ul>
  <ul class="navbar-nav hidden-xs-down">
    <li class="nav-item">
      <a class="nav-link" href="javascript:;"></a>
    </li>
  </ul>
</nav>
