<ngx-loading-bar color="rgb(51, 51, 51)" [includeSpinner]="false"></ngx-loading-bar>

<ng-sidebar-container class="app" [ngClass]="{
  'mode-boxed': options.boxed,
  'sidebar-opened': options.opened,
  'theme-light': options.theme == 'light',
  'theme-dark': options.theme == 'dark',
  'mode-push': _mode == 'push',
  'mode-dock': _mode == 'dock',
  'mode-over': _mode == 'over',
  'mode-slide': _mode == 'slide',
  'no-footer': routeOptions?.removeFooter,
  'map-header': routeOptions?.mapHeader}">
  <ng-sidebar [(opened)]="options.opened" [(mode)]="_mode" [position]="'left'" [dockedSize]="'80px'"
    [autoCollapseWidth]="'991'" [closeOnClickOutside]="isOver()" [showBackdrop]="isOver()" [sidebarClass]="'sidebar-panel'"
    #sidebar>
    <app-sidebar (messageEvent)="receiveMessage($event)" (toggleSidebar)="toogleSidebar()" [mode]="options.mode" appAccordion></app-sidebar>
  </ng-sidebar>

  <div ng-sidebar-content class="app-inner">
    <app-header (toggleSidebar)="toogleSidebar()" (openSearch)="openSearch(search)" (toggleFullscreen)="toggleFullscreen()"
      [heading]="routeOptions.heading"></app-header>

    <div class="main-content">
      <router-outlet></router-outlet>
      <app-footer></app-footer>
    </div>
  </div>

</ng-sidebar-container>

<ng-template #search let-c="close" let-d="dismiss">
  <form class="search__form" action="">
    <input class="search-input" name="search" type="search" placeholder="Search..." autocomplete="off" autocorrect="off"
      autocapitalize="off" spellcheck="false" autofocus="true" />
    <p class="text-muted"><small><strong>Hit enter to search or ESC to close</strong></small></p>
  </form>
  <div class="search-suggestions">
    <h6 class="text-uppercase"><strong>Suggestions?</strong></h6>
    <p class="text-primary">#medical #analytics #fitness #transport #ui #dashboard #admin #bootstrap #angular
      #typescript</p>
  </div>
  <button type="button" class="search-close" aria-label="Close search form" (click)="d('Cross click')">
    <i class="fi flaticon-close"></i>
  </button>
</ng-template>


<div class="configuration hidden-sm-down" [ngClass]="{'active': options.settings}">
  <div class="configuration-cog" (click)="options.settings = !options.settings">
    <i class="icon icon-basic-mixer2"></i>
  </div>
  <div class="card">
    <div class="card-header">
      Template Options
    </div>
    <div class="card-body">
      <small class="ff-headers text-uppercase mb-3"><strong>Explore Sidebar API</strong></small>
      <div class="custom-controls-stacked mb-2">

        <div class="custom-control custom-radio">
          <input name="radio-stacked" type="radio" value="push" [(ngModel)]="_mode" (change)="options.opened = true; options.mode = _mode"
            class="custom-control-input" id="pushMode">
          <label class="custom-control-label" for="pushMode">Push mode</label>
        </div>

        <div class="custom-control custom-radio">
          <input name="radio-stacked" type="radio" value="dock" [(ngModel)]="_mode" (change)="options.opened = true; options.mode = _mode"
            class="custom-control-input" id="dockedMode">
          <label class="custom-control-label" for="dockedMode">Docked mode</label>
        </div>

        <div class="custom-control custom-radio">
          <input name="radio-stacked" type="radio" value="over" [(ngModel)]="_mode" (change)="options.opened = true; options.mode = _mode"
            class="custom-control-input" id="overMode">
          <label class="custom-control-label" for="overMode">Over content mode</label>
        </div>

        <div class="custom-control custom-radio">
          <input name="radio-stacked" type="radio" value="slide" [(ngModel)]="_mode" (change)="options.opened = true; options.mode = _mode"
            class="custom-control-input" id="slideMode">
          <label class="custom-control-label" for="slideMode">Slide mode</label>
        </div>

      </div>

      <small class="ff-headers text-uppercase mb-3"><strong>Select A Layout</strong></small>
      <div class="d-flex align-items-center mb-2">
        <div class="custom-control custom-checkbox">
          <input type="checkbox" class="custom-control-input" [(ngModel)]="options.boxed" id="boxed">
          <label class="custom-control-label" for="boxed">Boxed</label>
        </div>
      </div>
      <div class="d-flex align-items-center mb-2">
        <div class="custom-control custom-checkbox">
          <input type="checkbox" class="custom-control-input" [ngModel]="routeOptions?.removeFooter" (ngModelChange)="routeOptions.removeFooter=$event"
            id="rfooter">
          <label class="custom-control-label" for="rfooter">Toggle footer</label>
        </div>
      </div>

      <small class="ff-headers text-uppercase mb-3"><strong>Select A Theme</strong></small>
      <div class="custom-controls-stacked mb-2">
        <div class="custom-control custom-radio">
          <input type="radio" class="custom-control-input" value="light" [(ngModel)]="options.theme" id="lightTheme">
          <label class="custom-control-label" for="lightTheme">Light theme</label>
        </div>

        <div class="custom-control custom-radio">
          <input type="radio" class="custom-control-input" value="dark" [(ngModel)]="options.theme" id="darkTheme">
          <label class="custom-control-label" for="darkTheme">Dark theme</label>
        </div>

      </div>

      <small class="ff-headers text-uppercase mb-3"><strong>Select A Language</strong></small>
      <div class="d-flex align-items-center">
        <select class="custom-select" [(ngModel)]="currentLang" #langSelect="ngModel" (ngModelChange)="translate.use(currentLang)"
          placeholder="Select language" style="min-width: 50%;">
          <option *ngFor="let lang of translate.getLangs()" [value]="lang">{{ lang }}</option>
        </select>
      </div>
    </div>
  </div>
</div>
