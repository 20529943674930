<nav class="navbar custom-navbar main-brand">
  <a class="navbar-brand mr-auto" [routerLink]="['/']" (click)="closeAccordion()">
    <!-- <img src="assets/images/logo.png" class="navbar-brand-logo" alt=""> -->
    <span class="docked-hidden">&nbsp;Telintec HD</span>
  </a>
  <ul class="navbar-nav">
    <li class="nav-item">
      <a href="javascript:;" class="nav-link" (click)="toggleSidebar.emit()">
        <i class="hamburger-icon v2" *ngIf="mode === 'over'">
          <span></span>
        </i>
      </a>
    </li>
  </ul>
</nav>

<app-menu></app-menu>
